import axios from 'axios';
import {useEffect , useState} from 'react';
import { useParams } from "react-router";
import Loader from 'react-loader';



const SearchBody = (location) => {

const {param} = useParams();

const [loading, setLoading] = useState(true);

let url =`https://shorturl.joshuadaniel.me?param=${param}`;
useEffect(()=>{
    setLoading(false)
    axios.get(url).then((response)=>{
        let count = response.data.data.length;
        console.log(count)
        if(count > 0){
        let getResponse = response.data.data[0].url;
         window.location.href= getResponse;
        }else{
            alert('Url Not Found Enter a Vaild Url');
            window.location.href = '/';
        }
setLoading(true)
    })    
},[url])


return (
    <Loader loaded={loading}>
        <div></div>
    </Loader>
)
}


export default SearchBody;