
import './header.scss'

const Header = () => {
    return(
        <div className="header">
            <nav>URL SHORTNER</nav>
        </div>
    );
}

export default Header;